import * as React from "react"
import SEO from "components/seo/SEO"
import Venues from "views/Venues"
import { graphql } from "gatsby"

export default function Index(props) {
  const locale = props.pageContext.locale

  return (
    <>
      <SEO locale={locale} />
      <Venues
        path="/"
        locale={locale}
        pageContext={props.pageContext}
        data={props.data}
      />
    </>
  )
}

export const query = graphql`
  fragment liveProps on WpLive {
    id: databaseId
    title
    content
    slug
    featuredImage {
      node {
        sourceUrl
      }
    }
    type: contentTypeName
    acf: venuesAcfFields {
      adresse {
        city
        country
        countryShort
        lat: latitude
        lng: longitude
        placeId
        postCode
        state
        stateShort
        streetAddress
        streetName
        streetNumber
        zoom
      }
      description
      visuels {
        visuel {
          node {
            id: databaseId
            alt: altText
            sourceUrl
          }
        }
      }
      horaires {
        lundi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        mardi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        mercredi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        jeudi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        vendredi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        samedi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        dimanche {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
      }
      reseauxSociaux {
        facebook
        instagram
        soundcloud
        twitter
        youtube
      }
      siteWeb
      eligibleAuPassCulture
    }
    seo {
      canonicalUrl
      description
      openGraphDescription
      openGraphTitle
      openGraphType
      title
      twitterDescription
      twitterTitle
      socialImage {
        sourceUrl
      }
    }
    moods {
      nodes {
        name
        slug
        id: databaseId
        acf: acfTaxonomyFilters {
          cacherPour
        }
      }
    }
    spaces {
      nodes {
        name
        slug
        id: databaseId
        acf: acfTaxonomyFilters {
          cacherPour
        }
      }
    }
    genres {
      nodes {
        name
        slug
        id: databaseId
        acf: acfTaxonomyFilters {
          cacherPour
        }
      }
    }
  }
  fragment clubProps on WpClub {
    id: databaseId
    title
    content
    slug
    featuredImage {
      node {
        sourceUrl
      }
    }
    type: contentTypeName
    acf: venuesAcfFields {
      adresse {
        city
        country
        countryShort
        lat: latitude
        lng: longitude
        placeId
        postCode
        state
        stateShort
        streetAddress
        streetName
        streetNumber
        zoom
      }
      description
      visuels {
        visuel {
          node {
            id: databaseId
            alt: altText
            sourceUrl
          }
        }
      }
      horaires {
        lundi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        mardi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        mercredi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        jeudi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        vendredi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        samedi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        dimanche {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
      }
      reseauxSociaux {
        facebook
        instagram
        soundcloud
        twitter
        youtube
      }
      siteWeb
      eligibleAuPassCulture
    }
    seo {
      canonicalUrl
      description
      openGraphDescription
      openGraphTitle
      openGraphType
      title
      twitterDescription
      twitterTitle
      socialImage {
        sourceUrl
      }
    }
    genres {
      nodes {
        name
        slug
        id: databaseId
        acf: acfTaxonomyFilters {
          cacherPour
        }
      }
    }
    spaces {
      nodes {
        name
        slug
        id: databaseId
        acf: acfTaxonomyFilters {
          cacherPour
        }
      }
    }
    moods {
      nodes {
        name
        slug
        id: databaseId
        acf: acfTaxonomyFilters {
          cacherPour
        }
      }
    }
  }
  fragment recordProps on WpRecord {
    id: databaseId
    title
    content
    slug
    featuredImage {
      node {
        sourceUrl
      }
    }
    type: contentTypeName
    acf: venuesAcfFields {
      adresse {
        city
        country
        countryShort
        lat: latitude
        lng: longitude
        placeId
        postCode
        state
        stateShort
        streetAddress
        streetName
        streetNumber
        zoom
      }
      description
      visuels {
        visuel {
          node {
            id: databaseId
            alt: altText
            sourceUrl
          }
        }
      }
      horaires {
        lundi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        mardi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        mercredi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        jeudi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        vendredi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        samedi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        dimanche {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
      }
      reseauxSociaux {
        facebook
        instagram
        soundcloud
        twitter
        youtube
      }
      siteWeb
      eligibleAuPassCulture
    }
    seo {
      canonicalUrl
      description
      openGraphDescription
      openGraphTitle
      openGraphType
      title
      twitterDescription
      twitterTitle
      socialImage {
        sourceUrl
      }
    }
    genres {
      nodes {
        name
        slug
        id: databaseId
        acf: acfTaxonomyFilters {
          cacherPour
        }
      }
    }
    spaces {
      nodes {
        name
        slug
        id: databaseId
        acf: acfTaxonomyFilters {
          cacherPour
        }
      }
    }
  }
  fragment studioProps on WpStudio {
    id: databaseId
    title
    content
    slug
    featuredImage {
      node {
        sourceUrl
      }
    }
    type: contentTypeName
    acf: venuesAcfFields {
      adresse {
        city
        country
        countryShort
        lat: latitude
        lng: longitude
        placeId
        postCode
        state
        stateShort
        streetAddress
        streetName
        streetNumber
        zoom
      }
      description
      visuels {
        visuel {
          node {
            id: databaseId
            alt: altText
            sourceUrl
          }
        }
      }
      horaires {
        lundi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        mardi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        mercredi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        jeudi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        vendredi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        samedi {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
        dimanche {
          ouvert
          horaires {
            ouverture
            fermeture
          }
          plageshoraires {
            ouverture
            fermeture
          }
        }
      }
      reseauxSociaux {
        facebook
        instagram
        soundcloud
        twitter
        youtube
      }
      siteWeb
      eligibleAuPassCulture
    }
    seo {
      canonicalUrl
      description
      openGraphDescription
      openGraphTitle
      openGraphType
      title
      twitterDescription
      twitterTitle
      socialImage {
        sourceUrl
      }
    }
    spaces {
      nodes {
        name
        slug
        id: databaseId
        acf: acfTaxonomyFilters {
          cacherPour
        }
      }
    }
    activities {
      nodes {
        name
        slug
        id: databaseId
        acf: acfTaxonomyFilters {
          cacherPour
        }
      }
    }
  }
  query (
    $today: Date!
    $id: Int = 0
    $currentIsLive: Boolean = false
    $currentIsClub: Boolean = false
    $currentIsStudio: Boolean = false
    $currentIsRecord: Boolean = false
  ) {
    currentLive: wpLive(databaseId: { eq: $id }) @include(if: $currentIsLive) {
      ...liveProps
    }
    currentClub: wpClub(databaseId: { eq: $id }) @include(if: $currentIsClub) {
      ...clubProps
    }
    currentStudio: wpStudio(databaseId: { eq: $id })
      @include(if: $currentIsStudio) {
      ...studioProps
    }
    currentRecord: wpRecord(databaseId: { eq: $id })
      @include(if: $currentIsRecord) {
      ...recordProps
    }
    events: allWpEvent(
      filter: { eventsAcfFields: { horaires: { debut: { gte: $today } } } }
      sort: { fields: eventsAcfFields___horaires___debut }
    ) {
      nodes {
        title
        id: databaseId
        locale {
          id
        }
        translated {
          type: contentTypeName
          databaseId
          slug
          locale {
            id
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        acf: eventsAcfFields {
          description
          imagePrincipale {
            node {
              sourceUrl
            }
          }
          horaires {
            debut
            fin
          }
          misEnAvant
          lieu {
            nodes {
              id: databaseId
              slug
              type: contentTypeName
            }
            #            ... on WpClub {
            #              id: databaseId
            #              slug
            #              type: contentTypeName
            #            }
            #            ... on WpStudio {
            #              id: databaseId
            #              slug
            #              type: contentTypeName
            #            }
            #            ... on WpLive {
            #              id: databaseId
            #              slug
            #              type: contentTypeName
            #            }
            #            ... on WpRecord {
            #              id: databaseId
            #              slug
            #              type: contentTypeName
            #            }
          }
          billeterie
          eligibleAuPassCulture
          tarif
        }
        genres {
          nodes {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
          }
        }
        seo {
          canonicalUrl
          description
          openGraphDescription
          openGraphTitle
          openGraphType
          title
          twitterDescription
          twitterTitle
          socialImage {
            sourceUrl
          }
        }
      }
    }
    eventsPasFinis: allWpEvent(
      sort: { fields: eventsAcfFields___horaires___debut }
      filter: {
        eventsAcfFields: {
          horaires: { debut: { lte: $today }, fin: { gte: $today } }
        }
      }
    ) {
      nodes {
        title
        id: databaseId
        locale {
          id
        }
        translated {
          type: contentTypeName
          databaseId
          slug
          locale {
            id
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        acf: eventsAcfFields {
          description
          imagePrincipale {
            node {
              sourceUrl
            }
          }
          horaires {
            debut
            fin
          }
          misEnAvant
          lieu {
            nodes {
              id: databaseId
              slug
              type: contentTypeName
            }
            #            ... on WpClub {
            #              id: databaseId
            #              slug
            #              type: contentTypeName
            #            }
            #            ... on WpStudio {
            #              id: databaseId
            #              slug
            #              type: contentTypeName
            #            }
            #            ... on WpLive {
            #              id: databaseId
            #              slug
            #              type: contentTypeName
            #            }
            #            ... on WpRecord {
            #              id: databaseId
            #              slug
            #              type: contentTypeName
            #            }
          }
          billeterie
          eligibleAuPassCulture
          tarif
        }
        genres {
          nodes {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
          }
        }
        seo {
          canonicalUrl
          description
          openGraphDescription
          openGraphTitle
          openGraphType
          title
          twitterDescription
          twitterTitle
          socialImage {
            sourceUrl
          }
        }
      }
    }
  }
`
